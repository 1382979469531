import React, {useState, useRef, useEffect, Fragment} from "react";
import "./Caps.css";
import { 
    setCameraToken, 
    getCameraToken, 
    getCameras, 
    setCameras, 
    filterMonitorsByQuality, 
    getCap, 
    storeCap, 
    storeLatestUrls, 
    getLatestUrls, 
    getUser, 
    getUserInformation,
    pasteAsPlainText,
    disableNewLines
} from "../utils/Common";
import { Button, Glyphicon, OverlayTrigger, Tooltip, ButtonGroup, PageHeader, Label, ButtonToolbar, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { View } from "react-native";
import { 
    loadCap, 
    getLatestStoredImages, 
    getMonitors, 
    getImagesFromCapThroughCloud, 
    recordUserAction, 
    patchLightMode, 
    changeCapName,
    prepareCapForShipping,
    loginToCamerasThroughAPI,
    getBuilder,
    deleteCapData
} from "../utils/bedrockAPI";
import soLoGo from "../images/southernliving.jpg";
import Paragon from "../images/paragon.png";
import SharingModal from "../components/SharingModal";
import CapModal from "../components/CapModal";
import ContentEditable from "react-contenteditable";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LoaderButton from "../components/LoaderButton";
// import ToggleSwitch from "../components/ToggleSwitchThreeWay";
// import { LazyLoadImage } from "react-lazy-load-image-component";

let UPDATE_INTERVAL = 10;
let TIMEOUT = 180;
const NO_CONNECTION = 8;
const RELOGIN = 180;

// %N - %d/%m/%y %H:%M:%S --> Name + timestamp formatter for cameras in ZM (default)
// %m/%d/%y %I:%M:%S %p --> Updated (12hr, am/pm, MURICA)

export default function Caps(props) {
    const [cap, setCap] = useState(null);
    const [localCameras, setLocalCameras] = useState(null);
    const [renderError, setRenderError] = useState(false);
    const [time, setTime] = useState(0);
    const [update, setUpdate] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [streaming, setStreaming] = useState(true);
    const [savedUrls, setSavedUrls] = useState(null);
    const [useSavedImage, setUseSavedImage] = useState(true);
    const [networkError, setNetworkError] = useState(false);
    const [buttonSize, setButtonSize] = useState("large");
    const [newUrls, setNewUrls] = useState(null);
    const [changingLights, setChangingLights] = useState(false);
    const [showSharingWindow,setShowSharingWindow] = useState(false);
    const [sharableCap, setSharableCap] = useState(null);
    const capName = useRef("");
    const nameElement = useRef(null);
    const [showCapModal,setShowCapModal] = useState(false);
    const [shipping, setShipping] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const componentMounted = useRef(true); // Handles react unmount errors for async functions in main body

    // 1 -- Start Clocks
    useEffect(() => {
        if(window.innerWidth<767) {
            setButtonSize("small");
        }
        const interval = setInterval(() => {
            setTime(time => time+1);
            setUpdate(update => update+1);
        },1000);

        return () => {
            clearInterval(interval);
            componentMounted.current = false; // This resets mounted on Caps unmount for all async functions
        }
    },[]);

    // Don't request too many images from the CAP
    useEffect(() => {
        if(streaming && time > 0 && time%TIMEOUT===0) {
            // setTime(0);
            setStreaming(false); //TODO: Change the next image text
        }
        if(time>0 && time%RELOGIN===0) {
            cameraLogin();
        }
        if(time > 0 && time%10===0 && TIMEOUT > 1000) {
            console.log("Clock:",time);
        }
    },[time]);

    async function cameraLogin(tempCap=null,reset=false) {
        try {
            if(tempCap==null) {
                tempCap = cap;
            }
            let t = getCameraToken();
            let camCredentials;
            if(t && typeof t === 'object' && tempCap.capId in t && !reset) {
                camCredentials = {access_token:t[tempCap.capId]}
            } else {
                if(typeof t !== 'object') t = null;
                camCredentials = await loginToCamerasThroughAPI(tempCap.capId);
                // console.log(camCredentials);
            }
            if(componentMounted.current) {
                setCameraToken({...t,...{[tempCap.capId]:camCredentials.access_token}});
            }
        } catch(e) {
            if(e.message === 'Network Error' || (e.response && e.response.status===504)) {
                setNetworkError(true);
            }
            // setRenderError(true);
        }
    }

    // Loads Images from cap through cloud
    async function cloudImages(reset=false) {
        try {
            const cams = filterMonitorsByQuality(localCameras,"medium");
            const t = getCameraToken();
            let ct = null;
            if(reset) cameraLogin(cap,true);
            if(t && cap.capId in t && !reset) ct = t[cap.capId];
            const tempCloudImages = await getImagesFromCapThroughCloud(cap,cams,ct);
            for(var tempUrl in tempCloudImages) {
                tempCloudImages[tempUrl].accessDate = new Date();
            }
            if(componentMounted.current) {
                setNewUrls(tempCloudImages);
                storeLatestUrls(tempCloudImages);
            }
            if(localCameras && tempCloudImages!=null && (localCameras.house.medium.monitorId in tempCloudImages)) {
                if(componentMounted.current)
                    setUseSavedImage(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Get's CAP object - most importantly - the status
    async function capObject() {
        try {
            var tempCap = await loadCap(props.match.params.capid);
            if(componentMounted.current) {
                storeCap(tempCap);
                setCap(tempCap);
            }
            return tempCap;
        } catch(e) {
            console.log(e);
        }
    }

    // Handle the periodic updates
    useEffect(() => {
        if(update > 0 && update%UPDATE_INTERVAL===0) {
            // Reloads the image urls so new images load on the update intervals
            if(streaming) {
                if(!useSavedImage && localCameras!=null) {
                    cloudImages();
                    capObject();
                }
            }
            setUpdate(0);
        }
        // if(update > 0 && update%NO_CONNECTION===0) {
        //     if(localCameras==null && savedUrls==null) {
        //         setRenderError(true);
        //     }
        // }
    },[update]);

    // 2 - Load the cap from memory 1st then api
    useEffect(() => {
        let isMounted = true;
        async function accessCap() {
            try {
                setIsLoading(true);
                var tempCap = getCap();
                // var tempCap = null;
                if(tempCap != null && tempCap.capId === props.match.params.capid) {
                    setCap(tempCap);
                }
                tempCap = await capObject();
                if(isMounted) {
                    cameraLogin(tempCap);
                }
                if(isMounted) {
                    setIsLoading(false);
                }
            } catch(e) {
                console.log(e);
                // if(isMounted) {
                    // setRenderError(true);
                // }
            }
        }
        accessCap();
        recordUserAction(getUser().userId,"cap",props.match.params.capid,{'userData':getUserInformation()});
        let user = getUser();
        if(user && user.userId === "85a0a743-c203-4e39-b16e-531c504303fc") {
            TIMEOUT = 100000000;
        }
        return () => { isMounted = false; };
    },[props.match.params.capid])

    // 3 - Load camera metadata and cloud image urls
    useEffect(() => {
        let isMounted = true;

        // Load monitor metadata from API
        async function handleLoadCameras() {
            try {
                const tempCams = await getMonitors(cap.capId);
                if(isMounted) {
                    setLocalCameras(tempCams);
                    setCameras(tempCams);
                }
            } catch(e) {
                // TODO: Clean this up so that it doesn't render error when the cap is down
                try {
                    cameraLogin();
                    console.log(e)
                    if(e.message === 'Network Error') {
                        if(isMounted)
                            setNetworkError(true);
                    }
                } catch(f) {
                    // if(isMounted)
                        // setRenderError(true);
                }
            }
        }
        // Attempt to load metadata from memory before fetching from API - probably don't need?
        async function getAllCameras() {
            try {
                const cams = getCameras();
                if(cams==null) {
                    handleLoadCameras();
                } else if (cams!=null && cams.capId !== cap.capId) {
                    handleLoadCameras();
                } else {
                    setLocalCameras(cams);
                }
            } catch(e) {
                if(e.message === 'Network Error') {
                    setNetworkError(true);
                }
                console.log(e);
            }
        }
        // Get images from cloud from last 2hr request or last live inpatch
        // We can load these urls from memory if available since they're the same as what the cloud will give us (in theory)
        async function loadSavedImages() {
            try {
                // These are the locally stored urls for the camera - will not be useful if click on new camera after long time
                var tempSavedUrls = getLatestUrls();
                let refetch = false;
                // Refetch if no savedurls or if the urls are more than 10 minutes old 
                if(tempSavedUrls && tempSavedUrls[Object.keys(tempSavedUrls)[0]] && tempSavedUrls[Object.keys(tempSavedUrls)[0]].accessDate) {
                    let ad = new Date(tempSavedUrls[Object.keys(tempSavedUrls)[0]].accessDate);
                    let dif = Math.round(((new Date())-ad)/(1000*60)); // minutes
                    if(dif > 10) {
                        refetch = true;
                    }
                }
                // Refetch if stored urls do not contain this cap's urls -- localCameras still null at this point :-/  
                if(tempSavedUrls && localCameras && localCameras.house.medium.monitorId) {
                    const mId = localCameras.house.medium.monitorId;
                    if(!(mId in tempSavedUrls)) {
                        refetch=true;
                    }
                }
                // This gets actual stored images from API
                if(refetch || tempSavedUrls==null) {
                    // console.log("this is getting called")
                    tempSavedUrls = await getLatestStoredImages(cap.capId);
                    for(var tempUrl in tempSavedUrls) {
                        tempSavedUrls[tempUrl].accessDate = new Date();
                    }
                    if(isMounted) 
                        storeLatestUrls(tempSavedUrls);
                }
                if(isMounted)
                    setSavedUrls(tempSavedUrls);
            } catch(e) {
                // if(isMounted) {
                    // setRenderError(true);
                // }
                console.log(e);
            }
        }

        if(cap!=null) {
            capName.current = cap.name;
            if(['DECOMISSIONED','DEAD'].includes(cap.status) && getUser().role!=="ADMIN") {
                setRenderError(true);
            } else if(['POWER_LOSS','ERROR'].includes(cap.health)) {
                setNetworkError(true);
                getAllCameras();
                loadSavedImages();
            }  else {
                cameraLogin();
                getAllCameras();
                loadSavedImages();
            }
        }
        return () => { isMounted = false; };
    },[cap]);

    useEffect(() => {
        let isMounted = true;
        async function accessUrls() {
            const tempSavedUrls = await getLatestStoredImages(cap.capId);
            // console.log(tempSavedUrls);
            for(var tempUrl in tempSavedUrls) {
                tempSavedUrls[tempUrl].accessDate = new Date();
            }
            if(isMounted) {
                storeLatestUrls(tempSavedUrls);
                setSavedUrls(tempSavedUrls);
            }
        }
        let fetchingSavedUrls = true;
        if(savedUrls!=null && localCameras !=null) {
            var correctCameras = false;
            const key0 = Object.keys(savedUrls)[0];
            for(var direction in localCameras) {
                for(var quality in localCameras[direction]) {
                    if(localCameras[direction][quality].monitorId === key0) {
                        correctCameras = true;
                    }
                }
            }
            if(!correctCameras && fetchingSavedUrls) {
                // console.log("accessURLs");
                accessUrls();
            }
        }
        return () => { isMounted = false; };
    },[savedUrls,localCameras]);

    // Runs every time anything changes
    useEffect(() => {
        let isMounted = true;
        async function reLoadSavedUrls() {
            const tempSavedUrls = await getLatestStoredImages(cap.capId);
            for(var tempUrl in tempSavedUrls) {
                tempSavedUrls[tempUrl].accessDate = new Date();
            }
            if(isMounted) {
                storeLatestUrls(tempSavedUrls);
                setSavedUrls(tempSavedUrls);
            }
        }
        // 4a replacement - load images from cap through cloud
        const t = getCameraToken();
        if(localCameras!=null && cap && (t && typeof t ==='object' && cap.capId in t) && newUrls==null) {
            if(isMounted) {
                setNewUrls({}); // So this only gets called once (temporary assignment)
                cloudImages();
            }
        }
        if(localCameras!=null && !useSavedImage && newUrls!=null && !(localCameras.house.medium.monitorId in newUrls)) {
            if(isMounted)
                setUseSavedImage(false);
        }
        if(localCameras!=null && useSavedImage && savedUrls!=null && !(localCameras.house.medium.monitorId in savedUrls)) {
            if(isMounted) 
                reLoadSavedUrls();
        }
        return () => { isMounted = false; };
    });
    
    function renderLiveTracker() {
        const tooltip = (
            <Tooltip id="refresh" style={{fontSize: "18px"}}>
                Refresh if stream is not loading
            </Tooltip>
        );
        const lights = (
            <Tooltip id="lights" style={{fontSize: "18px"}}>
                Set the mode of the flood lights
            </Tooltip>
        )
        let dateString = "";
        let displayLoadedFrom = false;
        if(savedUrls!=null && localCameras!=null && (localCameras.house.medium.monitorId in savedUrls)) {
            if(savedUrls[localCameras.house.medium.monitorId].captureDate) {
                dateString = (new Date(savedUrls[localCameras.house.medium.monitorId].captureDate)).toLocaleString();
                if((new Date())-(new Date(savedUrls[localCameras.house.medium.monitorId].captureDate))<432000000) {
                    displayLoadedFrom = true;
                }
            } else {
                displayLoadedFrom = false;
            }
        }
        async function setLightMode(e) {
            setChangingLights(true);
            await patchLightMode(cap.capId,e);
            var newCap = JSON.parse(JSON.stringify(cap));
            newCap.lights = e;
            setCap(newCap);
            await new Promise((resolve)=>setTimeout(resolve,5000));
            setChangingLights(false);
        }
        async function shipCap(e) {
            e.preventDefault();
            if(!cap.builderId) {
                window.alert("Assign a Builder First");
                return;
            }
            let answer = window.confirm("Are you sure you want to Create Shipping Labels for this CAP?");
            let builder = await getBuilder(cap.builderId);
            if(answer) {
                setShipping(true);
                const success = await prepareCapForShipping(cap.capId,builder.stripeId);
                console.log(success);
                if(success && success.body && success.body.shipping) {
                    window.open(success.body.shipping,"_blank");
                }
                // console.log(success);
                setShipping(false);
            }
        }
        async function deleteAllCapData(e) {
            e.preventDefault();
            let answer = window.confirm("Are you sure you want to delete ALL OF THE DATA for this CAP?");
            if(answer) {
                setDeleting(true);
                const deletedData = await deleteCapData(cap.capId);
                setDeleting(false);
            }
        }
        return (
            <View style={{flex:1, flexDirection: 'column'}}>
                <View style={{flex: 1, flexDirection: 'row', fontWeight: "500", fontSize: "18px", flexWrap: "wrap"}}>
                    <View style={{fontSize:"28px", paddingRight: "10px"}}>
                        <Label 
                            bsStyle={!streaming? "info" : !useSavedImage ? cap.health==="ONLINE" ? "success" : "warning" : networkError ? cap.health==="POWER_LOSS" ? "danger" : "warning" : "default"} 
                            onClick={() => {streaming ? void(0) : window.location.reload(false)}}
                            bsSize={buttonSize}
                        >
                            {/* {cap.health==="ONLINE" ? (<Glyphicon glyph="fire" />) : "" } */}
                            {/* {cap.health==="BATTERY" ? "(BATTERY BACKUP) - " : ""} // Need to figure out how to refresh interface on health update? */}
                            { 
                                streaming ? 
                                !useSavedImage ? 
                                cap.health === "ONLINE" ? 
                                "Live" : 
                                "Battery" :
                                networkError ? 
                                cap.health === "POWER_LOSS" ?
                                "Power Outage" :
                                "Waiting for Cell Connection" : 
                                update%3===0 ? "Loading.\xa0\xa0" : 
                                update%3===1 ? "Loading..\xa0" : 
                                "Loading..." :
                                "Refresh"
                            }
                        </Label>
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1}}>
                        {getUser().role==="ADMIN" && cap && cap.lights && (
                            <View style={{paddingRight:"5px"}}>
                                {/* <ToggleSwitch vals={['On','Motion','Off']} sel="Motion" /> */}
                                {/* TODO: figure out how to make sure the default selected toggle is the correct one from the db*/}
                                <OverlayTrigger placement="left" overlay={lights}>
                                    <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="Lights" value={cap.lights} defaultValue="AUTO" onChange={setLightMode}>
                                            <ToggleButton bsSize={buttonSize} bsStyle="info" value="ON" disabled={changingLights}>On</ToggleButton>
                                            <ToggleButton bsSize={buttonSize} bsStyle="info" value="AUTO" disabled={changingLights}>Motion</ToggleButton>
                                            <ToggleButton bsSize={buttonSize} bsStyle="info" value="OFF" disabled={changingLights}>Off</ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </OverlayTrigger>
                            </View>
                        )}
                        <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                            {getUser().role==="ADMIN" && cap.status==="BUILD" && (
                                <>
                                <View style={{paddingRight:"5px"}}>
                                    <LoaderButton
                                        bsSize="large"
                                        isLoading={deleting}
                                        onClick={(e) => deleteAllCapData(e)}
                                    >
                                        <Glyphicon glyph="trash" />
                                    </LoaderButton>
                                </View>
                                <View style={{paddingRight:"5px"}}>
                                    <LoaderButton
                                        bsSize="large"
                                        isLoading={shipping}
                                        onClick={(e) => shipCap(e)}
                                    >
                                        <Glyphicon glyph="barcode" />
                                    </LoaderButton>
                                </View>
                                </>
                            )}
                            <OverlayTrigger placement="bottom" overlay={tooltip}>
                                <Button
                                    bsStyle="info"
                                    bsSize={buttonSize}
                                    // disabled={isLoading}
                                    onClick={() => {window.location.reload(false)}}
                                >
                                    <Glyphicon glyph="refresh"></Glyphicon>
                                </Button>
                            </OverlayTrigger>
                        </View>
                    </View>
                </View>
                <div style={{paddingTop:"5px", paddingLeft: "10px"}}>
                {
                    savedUrls!=null && localCameras!=null  && (localCameras.house.medium.monitorId in savedUrls)?
                    useSavedImage ? displayLoadedFrom ?
                        "Loaded from "+dateString+" EST": "": 
                        ( 
                            streaming ? (
                                <div>
                                    {/* <Glyphicon glyph="camera" /> */}
                                    {update<2 ? "Now" : update+"s ago"}
                                </div>
                            ) : "Click Refresh to Update"
                        ) : null
                }
                </div>
            </View>
        );
    }

    function renderCameras() {
        return (
            <View stlye={{flex:1}}>
                {renderLiveTracker()}
                {localCameras && ((!useSavedImage) ? (
                    <View style={{flex: 1, flexDirection: 'column'}}>
                        <View style={{flex: 1, flexDirection: 'row', alignItems: "flex-start"}}>
                            {localCameras && newUrls!=null ? renderCamera(localCameras.house.medium) : null}
                            {localCameras && newUrls!=null ? renderCamera(localCameras.neighbor.medium) : null}
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', alignItems: "flex-start"}}>
                            {localCameras && newUrls!=null && localCameras.street.medium.width > 0 ? renderCamera(localCameras.street.medium) : null}
                            {localCameras && newUrls!=null && localCameras.driveway.medium.width > 0 ? renderCamera(localCameras.driveway.medium) : null}
                        </View>
                    </View>
                ) : (
                    <View style={{flex: 1, flexDirection: 'column'}}>
                        <View style={{flex: 1, flexDirection: 'row', alignItems: "flex-start"}}>
                            {(savedUrls!=null) ? renderCamera(localCameras.house.medium) : null}
                            {(savedUrls!=null) ? renderCamera(localCameras.neighbor.medium) : null}
                        </View>
                        <View style={{flex: 1, flexDirection: 'row', alignItems: "flex-start"}}>
                            {(savedUrls!=null && localCameras.street.medium.width > 0) ? renderCamera(localCameras.street.medium) : null}
                            {(savedUrls!=null && localCameras.driveway.medium.width > 0) ? renderCamera(localCameras.driveway.medium) : null}
                        </View>
                    </View>
                ))}
            </View>
        );
    }

    function renderCamera(camera) {
        async function urlsExpired() {
            setUseSavedImage(true); // Go back to stale images - camToken probably out of date or missing
            if(camera.name==='House_High_Res') // Only call once to reset images
                cloudImages(true); // reset camToken on fetch, change to live once fetched

            // const tempSavedUrls = await getLatestStoredImages(cap.capId);
            // for(var tempUrl in tempSavedUrls) {
            //     tempSavedUrls[tempUrl].accessDate = new Date();
            // }
            // storeLatestUrls(tempSavedUrls);
            // setSavedUrls(tempSavedUrls);
        }
        let render = true;
        if(useSavedImage && !(camera.monitorId in savedUrls)) {
            render = false;
        }
        if(!useSavedImage && !(camera.monitorId in newUrls)) {
            render = false;
        }
        // if(!useSavedImage && (camera.monitorId in newUrls) && newUrls[camera.monitorId].accessDate) {
        //     let ad = newUrls[camera.monitorId].accessDate;
        //     let dif = Math.round(((new Date())-ad)/(1000*60)); // minutes
        //     if(dif > 120) {
        //         render = false;
        //         console.log("Not rendering because newUrls Expired",dif,ad);
        //         urlsExpired();
        //     }            
        // }
        // if(useSavedImage && (camera.monitorId in savedUrls) && savedUrls[camera.monitorId].accessDate) {
        //     let ad = savedUrls[camera.monitorId].accessDate;
        //     let dif = Math.round(((new Date())-ad)/(1000*60)); // minutes
        //     if(dif > 10) {
        //         render = false;
        //         console.log("Not rendering because savedUrls Expired",dif,ad);
        //     }
        // }
        if (render && !isLoading) {
            return (
                <LinkContainer style={{padding:5, width: "50%", height: undefined, aspectRatio: 1.4667, cursor: "pointer"}} 
                to={`/caps/${cap.capId}/camera/${camera.monitorId}`}>
                    <img  
                        src={!useSavedImage ? newUrls[camera.monitorId].url : savedUrls[camera.monitorId].url}
                        alt={`${camera.name} Reloading... `}
                        onError={() => urlsExpired()}
                    />
                </LinkContainer>
            )
        } else {
            return null;
        }
    }
    
    function renderHeader() {
        const accessTooltip = (
            <Tooltip id="refresh" style={{fontSize: "18px"}}>
                Sign up for a CAP to get access to these premium features
            </Tooltip>
        );
        const editTooltip = (
            <Tooltip id="edit" style={{fontSize: "18px"}}>
                Edit the motion zones for this CAP
            </Tooltip>
        )
        function renderMotionHistoryButton() {
            return (
                <Button
                    bsStyle="primary"
                    bsSize={buttonSize}
                    disabled={getUser().caps[props.match.params.capid].accessType==="DEMO"}
                    onClick={() => props.history.push(`/caps/${cap.capId}/events`)}
                >
                    Motion History
                </Button>
            );
        }
        function renderProjectFilesButton() {
            return (
                <Button
                    bsStyle="primary"
                    bsSize={buttonSize}
                    disabled={getUser().caps[props.match.params.capid].accessType==="DEMO"}
                    onClick={() => props.history.push(`/caps/${cap.capId}/files`)}
                >
                    Project Files
                </Button>
            );
        }
        var handleNameChange = async function(e) {
            const name = e.target.value.replace(/(<([^>]+)>)/ig,"");
            capName.current = name;
        }
        var highlightAll = function(e) {
            var range = document.createRange();
            range.selectNodeContents(nameElement.current.getEl());
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
        var saveToServer = function(e) {
            if(capName.current.trim()!=="" && capName.current != null) {
                changeCapName(cap.capId,capName.current)
            }
        }
        return (
            <div>
                {/* <PageHeader>Cameras</PageHeader> */}
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{flex: 1, flexDirection: 'row', flexGrow: 10, flexWrap: 'wrap'}}>
                        <ButtonGroup>
                            <Button
                                bsStyle="primary"
                                bsSize={buttonSize}
                                onClick={() => props.history.push(`/dashboard`)}
                            >
                                Dashboard
                            </Button>
                            {getUser().caps[props.match.params.capid].accessType!=="DEMO" && (
                            //     <React.Fragment>
                            //         <OverlayTrigger placement="bottom" overlay={accessTooltip}>
                            //             {renderMotionHistoryButton()}
                            //         </OverlayTrigger>
                            //         {/* <OverlayTrigger placement="right" overlay={accessTooltip}>
                            //             {renderProjectFilesButton()}
                            //         </OverlayTrigger> */}
                            //     </React.Fragment>
                            // ) : (
                                <React.Fragment>
                                    {renderMotionHistoryButton()}
                                    {/* {renderProjectFilesButton()} */}
                                </React.Fragment>
                            )}
                        </ButtonGroup>
                        {cap && cap.capId === props.match.params.capid && getUser().caps[cap.capId].accessType==="OWNER" && (
                            <View style={{flex:1, flexDirection:"row", justifyContent:"flex-end"}}>
                                {buttonSize==="large" ? (
                                    <OverlayTrigger placement="left" overlay={(
                                        <Tooltip id="share" style={{fontSize:"18px"}}>
                                            Share your CAP
                                        </Tooltip>
                                    )}>
                                        <Button
                                            bsStyle="info"
                                            bsSize={buttonSize}
                                            onClick={() => {
                                                setShowSharingWindow(true);
                                                setSharableCap(JSON.parse(JSON.stringify(cap)));
                                            }}
                                        >
                                            <Glyphicon glyph="share" />
                                        </Button>
                                    </OverlayTrigger>
                                ) : (
                                    <Fragment />
                                )}
                                <View style={{paddingLeft:"5px"}}>
                                    <OverlayTrigger placement="bottom" overlay={editTooltip}>
                                        <Button
                                            bsStyle="success"
                                            bsSize={buttonSize}
                                            disabled={!(cap && (cap.health==="ONLINE" || cap.health==="BATTERY"))}
                                            onClick={() => props.history.push(`/caps/${cap.capId}/zones`)}
                                        >
                                            {/* <Glyphicon glyph="edit" /> */}
                                            {buttonSize==="large" ? "Motion Zones" : "Zones"}
                                        </Button>
                                    </OverlayTrigger>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
                <PageHeader style={{marginTop:"-10px"}}>
                    {cap && cap.capId==="28c626eb-971a-46c5-afe6-b066eec8e397" && (
                        <View style={{flex:1, flexDirection: "row", justifyContent:"space-between"}}>
                            <View style={{width:"20%"}}>
                                <a href="https://paragonbuildinggroup.com/">
                                    <img src={Paragon} alt="Paragon" />
                                </a>
                            </View>
                            <View style={{width:"40%"}}>
                                <a href="http://www.southernlivingcustombuilder.com/">
                                    <img src={soLoGo} alt="Southern Living" />
                                </a>
                            </View>
                        </View>
                    )}
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                        {/* <div style={buttonSize==="large" ? {fontSize:"36px"}:{fontSize:"22px"}}>
                            {cap.name}<small> {cap.neighborhood && "("+cap.neighborhood+")"}{getUser().role==="ADMIN"&&"["+cap.port+"]"}</small>
                        </div> */}
                        <View style={buttonSize==="large" ? {fontSize:"36px"}:{fontSize:"22px"}}>
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-start"}}>
                                <ContentEditable
                                    html={capName.current}
                                    // className="ContentEditable-header"
                                    disabled={isLoading || (props.match.params.capid && getUser().caps[props.match.params.capid].accessType!=="OWNER")}
                                    onPaste={pasteAsPlainText}
                                    onKeyPress={disableNewLines}
                                    onFocus={highlightAll}
                                    onChange={handleNameChange}
                                    onBlur={saveToServer}
                                    ref={nameElement}
                                />
                                <div>
                                    <small>
                                        &nbsp;{cap.neighborhood && "("+cap.neighborhood+")"}{getUser().role==="ADMIN"&&"["+cap.port+"]"}
                                    </small>
                                </div>
                            </View>
                        </View>
                        {getUser().role === "ADMIN" && (
                            <View style={{flex:1,flexDirection:"row",justifyContent:"flex-end"}}>
                                <View style={{paddingRight:"5px"}}>
                                <CopyToClipboard
                                    text={cap ? cap.capId : ""}
                                >
                                    <Button
                                        bsSize={buttonSize}
                                        bsStyle="default"
                                    >
                                        <Glyphicon glyph="duplicate" />
                                    </Button>
                                </CopyToClipboard> 
                                </View>
                                <Button
                                    bsStyle="danger"
                                    bsSize={buttonSize}
                                    onClick={() => setShowCapModal(true)}
                                >
                                    <Glyphicon glyph="pencil" />
                                </Button>    
                            </View>
                        )}
                    </View>
                </PageHeader>
                {/* {localCameras!=null && (localCameras.house.medium.monitorId in savedUrls) && renderCameras()} */}
                {renderCameras()}
            </div>        
        );
    }

    function renderNoCap() {
        return (
            <div style={{paddingTop: "100px", textAlign: "center"}}>
                <h3>Cap is not set up yet, please contact Bedrock Wireless for your installation status</h3>
            </div>
        )
    }

    return (
        <div className="Caps" style={{paddingBottom: "50px"}}>
            {
                (   
                    renderError ? renderNoCap() :
                    // !isLoading &&
                    cap && 
                    // savedUrls &&
                    renderHeader()
                )
            }
            {cap && savedUrls && (
                <SharingModal 
                    cap={sharableCap}
                    show={showSharingWindow}
                    setErrorMessage={props.setErrorMessage}
                    setErrorStatus={props.setErrorStatus}
                    setShowError={props.setShowError}
                    setShowSharingWindow={setShowSharingWindow}
                />
            )}
            {cap && (
                <CapModal
                    cap={cap}
                    show={showCapModal}
                    setShowCapModal={setShowCapModal}
                />
            )}
        </div>
    );
}